html, body, #viewDiv {
   padding: 0;
   margin: 0;
   height: 97%;
   width: 100%;
}

.banner {
   background: #ffffff;
   padding-top: 15px;
   float: left;
}

.logo-container {
   float: left;
   padding-right: 30px;
}

.xpo-logo {
   height: 27px;
   width: 180px;
   padding: 5px 5px 5px 15px;
}

.map-name {
   float: left;
   font-weight: bold;
   font-family: Roboto, sans-serif;
   font-size: 20px;
   padding: 7px 15px 15px 15px;
}

/* Styling for annoucement:  initial load div is hidden and underneath map (both absolute and z-index) */
#browser-alert {
   position: absolute;
   font-family: Roboto, sans-serif;
   font-size: 15px;
   top: 75px;
   left: 15px;
   z-index: -1;
   visibility: hidden;
}

/* Styling for browser icons: resizes the png's */
.browser-icon {
   height: 50px;
   width: 50px;
   padding: 10px 10px 10px 10px; 
}

/* Aligns the icons to be under the text */
#browser-icon-div{
   text-align: center;
}

::placeholder { /* input placeholder text */
   color: #9b9b9b !important;
   font-family: Roboto, sans-serif;
   font-weight: 350;
}

/* Overriding ESRI styles as needed */
.esri-menu {
   z-index: 2; /* make sure menus (like the search pulldown) are not hidden behind legend */
}

.esri-ui .esri-popup {
   z-index: 2; /* make sure popups are not hidden behind legend */
}

.esri-locate { /* locate widget should be above the zoom widget ... not to the side */
   position:absolute;
   bottom: 100px;
   right: 15px;
}

.esri-search__sources-button { /* Remove the search sources pulldown to the left of search input box */
   display: none;
}

/* Color the map icons based on XPO UX feedback */
.esri-icon-search,
.esri-icon-description,
.esri-icon-collapse,
.esri-icon-locate,
.esri-icon-plus,
.esri-icon-minus {
   color: #6f6f6f;
}

.esri-icon-search:hover,
.esri-icon-description:hover,
.esri-icon-collapse:hover,
.esri-icon-locate:hover,
.esri-icon-plus:hover,
.esri-icon-minus:hover {
   color: #4a4a4a;
}

.esri-ui-top-left {
   position:absolute;
   top: 35px;
}

/* Begin ESRI popup style changes */

/* Main popup container style changes: make it smaller, no padding, and color changes */
.esri-popup__main-container {
   max-width: 450px;
   max-height: 400px !important; /* Need the important here to remove the scrollbar */
   color: white;
   background-color: #4a4a4a;
   padding: 0;
}

/* Background of popup header and footer will be black */
.esri-popup__header,
.esri-popup__footer {
   background-color: black;
}

/* Don't wrap words in the header title text */
.esri-popup__header-title {
   white-space: nowrap;
}

/* Color change for the "x of y" item in the popup footer */
.esri-popup__feature-menu-button {
   color: white;
   background-color: black;
}

.esri-popup__feature-menu-button:hover {
   color: white;
}

/* Color change for the popup action text (e.g. zoom to) */
.esri-popup__action-text {
   color: white;
}

.esri-popup__action-text:hover {
   color: lightgrey;
}

/* Remove the padding for table content */
.esri-feature__fields {
   padding: 0;
}

/* Left column of the table content. Make it a bit narrower */
.esri-widget__table tr th {
   width: 40%;
}

/* Right column of the table content. Make it wider and a more prominent font in white */
.esri-widget__table tr td {
   font-weight: 600;
   color: white;
   width: 60%;
}

/* Don't alternate the table row background colors. Make them all XPO dark gray, and the text white */
.esri-widget__table tr:nth-child(odd),
.esri-widget__table tr:nth-child(even) {
   color: white;
   background-color: #4a4a4a;
   padding: 4px;
}

/* Change the "view" link in the right table column to "details" and make it white */
.esri-widget__table tr a {
   font-size: 0;
}

.esri-widget__table tr a:after {
   color: white;
   content: 'Details';
   font-size:12px;         /* original font size */
   text-decoration: underline;
}

/* Make the popup icons white and lightgrey on hover */
.esri-icon-close,
.esri-icon-zoom-in-magnifying-glass,
.esri-icon-dock-right,
.esri-icon-left-triangle-arrow,
.esri-icon-right-triangle-arrow
{
   color: white;
}

.esri-icon-close:hover,
.esri-icon-zoom-in-magnifying-glass:hover,
.esri-icon-dock-right:hover,
.esri-icon-left-triangle-arrow:hover,
.esri-icon-right-triangle-arrow:hover {
   color: lightgrey;
}

/* Make the pointer triangle black */
.esri-popup__pointer-direction {
   background-color: black;
}

/* Make Link always white */
.esri-popup__content div a {
   color: white;
}
/* End ESRI popup style changes */

/* Styling and position of the datepicker */
#datepicker {
   font-family: Roboto, sans-serif;
   font-size: 14px;
   font-weight: 350;
   width: 235px;
   height: 25px;
   color: #9b9b9b;
   border: 0px;
   box-shadow: 0 3px 3px -2px #9b9b9b;
   position: absolute;
   z-index: 1;
   left: 15px;
   top: 75px;
   padding-left: 5px;
}
